import React from 'react';
import { Box } from '@mui/material';
import Hexagon from '@/components/ui/hexagon';
import Circle from '@/components/ui/circle';
import heroImg from '@/public/heroImg.png';
import reactImg from '@/public/React.png';
import nextImg from '@/public/Next.js.png';
import tsImg from '@/public/TypeScript.png';
import Image from 'next/image';

const HEXAGON_SIZE = {
  xs: 650,
  sm: 280,
  lg: 600,
};

const CIRCLE_SIZE = {
  xs: 0,
  sm: 60,
  lg: 100,
};

const STROKE = {
  xs: 200,
  sm: 200,
  lg: 200,
};

const AnimatedSvgGroup = ({ size = 'lg' }) => {
  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
        }}>
        <Box
          sx={{
            position: 'absolute',
            top: { xs: 'unset', sm: '50%' },
            bottom: { xs: '20%', sm: 'unset' },
            left: { xs: '50%', sm: '50%' },
            transform: {
              xs: 'translate(-50%,0)',
              sm: 'translate(-50%,-50%)',
            },
            width: { xs: '150px', sm: '50%' },
            height: { xs: '150px', sm: '50%' },
            backgroundColor: 'primary.main',
            borderRadius: '9999px',
          }}>
          <Image
            src={heroImg}
            alt='hero block image'
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '9999px',
              boxShadow: '10px 10px 50px #f5f5f5',
            }}
          />
        </Box>
        <Hexagon
          size={HEXAGON_SIZE[size]}
          strokeDasharray={STROKE[size]}
          isReversed
        />
        <Box
          sx={{
            position: 'absolute',
            top: '20%',
            left: '5%',
          }}>
          <Circle size={CIRCLE_SIZE[size]}>
            <Image
              src={reactImg}
              alt='react logo'
              style={{
                width: '50%',
                height: '50%',
                objectFit: 'cover',
              }}
            />
          </Circle>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '82%',
            left: '45%',
          }}>
          <Circle size={CIRCLE_SIZE[size]}>
            <Image
              src={nextImg}
              alt='next.js logo'
              style={{ width: '50%', height: '50%', objectFit: 'cover' }}
            />
          </Circle>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '15%',
            right: '8%',
          }}>
          <Circle size={CIRCLE_SIZE[size]}>
            <Image
              src={tsImg}
              alt='Typescript logo'
              style={{ width: '50%', height: '50%', objectFit: 'cover' }}
            />
          </Circle>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
          }}>
          <Hexagon
            size={HEXAGON_SIZE[size] + 50}
            strokeDasharray={STROKE[size]}
            duration={60}
          />
        </Box>
      </Box>
    </>
  );
};

export default AnimatedSvgGroup;
