'use client';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Box, Grid, Container, Button } from '@mui/material';
import Link from 'next/link';
import CustomPrismicText from '../../components/prismic/customPrismicText';
import BackgroundImage from '@/components/prismic/backgroundImage';
import AnimatedSvgGroup from '@/components/ui/animatedSvgGroup';
import zIndex from '@mui/material/styles/zIndex';

const styles = {
  container: {
    paddingLeft: {
      xs: '18px',
      sm: '32px',
      md: '96px',
      lg: '80px',
      xl: 'unset',
    },
    paddingRight: {
      xs: '18px',
      sm: '32px',
      md: '96px',
      lg: '80px',
      xl: 'unset',
    },
  },
  section: {
    position: 'relative',
    height: '100vh',
    minHeight: { xs: '900px', sm: '600px' },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  img: {
    maxWidth: '100%',
  },
  gridContainer: {
    position: 'relative',
    zIndex: 0,
    alignItems: 'center',
    color: 'white',
  },
  descriptionBlock: {
    marginTop: '20px',
  },
  description: {
    fontSize: '20px',
  },
  btn: {
    marginTop: '20px',
  },
};

const Hero = ({ slice }) => {
  const {
    primary: {
      title,
      subtitle,
      description,
      image,
      btn_text: btnText,
      btn_link: btnLink,
    },
  } = slice;

  const variant = slice.variation;

  return (
    <Box
      data-slice-type={slice.slice_type}
      data-slice-variation={variant}
      component='section'
      sx={{
        ...styles.section,
        backgroundImage:
          variant === 'default'
            ? 'linear-gradient(-45deg, rgba(46, 48, 57,0.9), rgba(46, 48, 57,0.1) 30%, rgba(46, 48, 57,0.9) 80%)'
            : 'url("https://www.transparenttextures.com/patterns/asfalt-light.png")',
        backgroundColor: variant === 'default' ? 'unset' : '#2e3039',
      }}>
      {variant === 'default' && <BackgroundImage field={image} />}

      <Container maxWidth='xl' sx={styles.container}>
        <Grid container sx={styles.gridContainer}>
          <Grid
            component={motion.div}
            item
            sm={8}
            md={7}
            initial={{
              opacity: 0,
              y: -50,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            viewport={{
              once: true,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1,
            }}>
            <CustomPrismicText field={title} />

            <CustomPrismicText field={subtitle} />

            <Box sx={styles.descriptionBlock}>
              <CustomPrismicText field={description} sx={styles.description} />
            </Box>

            <Button
              variant='outlined'
              sx={styles.btn}
              component={Link}
              href='#about'>
              <CustomPrismicText field={btnText} />
            </Button>
          </Grid>

          {variant === 'secondVariant' && (
            <Grid
              item
              xs={12}
              sm={4}
              md={5}
              sx={{ position: 'relative', zIndex: -1 }}>
              <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <AnimatedSvgGroup size='xs' image={image} />
              </Box>
              <Box sx={{ display: { xs: 'none', sm: 'block', lg: 'none' } }}>
                <AnimatedSvgGroup size='sm' image={image} />
              </Box>
              <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                <AnimatedSvgGroup size='lg' image={image} />
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

Hero.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default Hero;
