import { rgbToRgba } from '@/helpers/transformRgbToRgba';
import { Box } from '@mui/material';
import { motion } from 'framer-motion';

const styles = {
  outline: {
    position: 'absolute',
    inset: '0px',
    outlineStyle: 'solid',
    outlineWidth: '6px',
    outlineOffset: '-6px',
    borderStyle: 'solid',
    borderWidth: '6px',
    borderColor: 'transparent',
    borderRadius: '9999px',
  },
};

const Circle = ({
  children,
  sx,
  isAnimated = false,
  size = 100,
  color = 'rgb(255,255,255)',
}) => {
  return (
    <Box
      sx={{
        ...sx,
        position: 'relative',
        height: `${size}px`,
        width: `${size}px`,
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '9999px',
        // bgcolor: '#2E3039',
        bgcolor: '#F5F5F5',
      }}>
      <Box
        component={motion.div}
        animate={isAnimated && { rotate: 360 }}
        transition={{ ease: 'linear', duration: 15, repeat: Infinity }}
        sx={{
          ...styles.outline,
          outlineColor: rgbToRgba(color, 0.1),
          borderTopColor: isAnimated ? rgbToRgba(color, 0.3) : '',
        }}
      />
      {children}
    </Box>
  );
};

export default Circle;
